interface Hole {
    hole: number;
    distance: number;
    par: number;
    hcp: number;
}

// Enum for golf tee colors
enum TeeColor {
    Red = 'Red',
    Blue = 'Blue',
    White = 'White',
    Black = 'Black',
    Gold = 'Gold',
    Green = 'Green',
    Silver = 'Silver',
    Orange = 'Orange',
    Purple = 'Purple',
    Yellow = 'Yellow',
}

interface Tee {
    id: string;
    courseId: string;
    clubId: string;
    name: string;
    color: TeeColor;
    courseParForTee: number;
    rating: number;
    slope: number;
    type: string;
    holes:{[hole:string]: Hole};
    totalDistance: number;
}

export type {Tee, Hole};

export {TeeColor};
