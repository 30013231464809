
import {II18n} from '@shared/models/I18n';
import {Sport} from '@shared/models/sport';

enum ScoreCalculationDirection {
    HighestScore = 'HIGHEST_SCORE',
    LowestScore = 'LOWEST_SCORE',
}

const ScoreCalculationArray = [ScoreCalculationDirection.HighestScore, ScoreCalculationDirection.LowestScore];

enum RegistrationType {
    Players = 'PLAYERS',
    Teams = 'TEAM'
}

enum FixtureRuleType {
    MatchPlay = 'golf:1',
    StrokePlay = 'golf:2',
    Stableford = 'golf:3',
    WinLose = 'golf:4',
}

enum ScoreParticipantInput {
    Player = 'PLAYER',
    Team = 'TEAM',
    All = 'ALL',
}

interface FixtureRule {
    // Only used for match play https://golftoday.co.uk/dormie-meaning-and-origin/
    dormie?: boolean;
    id: FixtureRuleType | string;
    name: II18n;
    numberOfParticipants?: number;
    playersPerTeam?: number;
    registrationType?: RegistrationType;
    sport: Sport;
    scoreCalculation: ScoreCalculationDirection;
    scoreInput?: ScoreParticipantInput;
}

const MatchPlayFixtureRule: FixtureRule =
{
    id: 'golf:1',
    sport: Sport.Golf,
    name: {
        en: 'Match play',
    },
    scoreCalculation: ScoreCalculationDirection.HighestScore,
};

const StrokePlayFixtureRule: FixtureRule =
{
    id: 'golf:2',
    sport: Sport.Golf,
    name: {
        en: 'Stroke play',
    },
    scoreCalculation: ScoreCalculationDirection.LowestScore,
};

const StablefordFixtureRule: FixtureRule =
{
    id: 'golf:3',
    sport: Sport.Golf,
    name: {
        en: 'Stableford',
    },
    scoreCalculation: ScoreCalculationDirection.HighestScore,
};

const WinLoseFixtureRule: FixtureRule =
{
    id: 'golf:4',
    sport: Sport.Golf,
    name: {
        en: 'Win/Lose',
    },
    scoreCalculation: ScoreCalculationDirection.HighestScore,
};

const fixtureRules: FixtureRule[] = [MatchPlayFixtureRule, StrokePlayFixtureRule, StablefordFixtureRule, WinLoseFixtureRule];
const ScoreFixtureRulesIds = [StrokePlayFixtureRule, StablefordFixtureRule].map((rule) => rule.id);

const LiveScoreFixtureRulesIds = [FixtureRuleType.Stableford];

export type {FixtureRule};

export {
    RegistrationType,
    ScoreCalculationDirection,
    ScoreCalculationArray,
    ScoreParticipantInput,
    FixtureRuleType,
    fixtureRules,
    MatchPlayFixtureRule,
    StrokePlayFixtureRule,
    StablefordFixtureRule,
    WinLoseFixtureRule,
    ScoreFixtureRulesIds,
    LiveScoreFixtureRulesIds,
};
