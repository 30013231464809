import {Hole, Tee, TeeColor} from '@shared/models/tee';
import {ParticipantTee} from '@shared/models/scorecard';
import {sortBy} from 'lodash';

const sortTeesByDistance = (tees: Tee[]): Tee[] => {
    return sortBy(tees, 'totalDistance');
};

const teeName = (tee: Tee) => `${tee.name} (${tee.totalDistance}m)`;

const teeColorString = (tee: Tee) => {
    switch (tee.color) {
        case TeeColor.White: return 'white';
        case TeeColor.Black: return 'black';
        default:return `${tee.color.toLowerCase()}.500`;
    }
};

const findParticipantTee = (uid: string, tees: Tee[], participantTees: ParticipantTee[]): Tee => {
    const participantTee = participantTees.find((participantTee) => participantTee.uid === uid)!;

    return tees.find((tee) => tee.id === participantTee.teeId)!;
};

const sortTeeHoles = (tee?: Tee): Hole[] => {
    return sortBy(Object.values(tee?.holes ?? {}), 'hole');
};

export {
    sortTeesByDistance,
    teeName,
    teeColorString,
    findParticipantTee,
    sortTeeHoles,
};
